import React, { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { StyledFeedbackButton, StyledFeedbackWrapper, StyledFeedbackHeader, StyledPregeneratedResponseButton, StyledPregeneratedResponseWrapper, StyledFeedbackBox } from './styled'

interface FeedbackWidgetProps {
  open: boolean
  regenerated: boolean
  onClose: () => void
  onSubmit: (text: string) => void
}

const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({ open, regenerated, onClose, onSubmit }) => {
  const [text, setText] = useState('')
  const [showFeedbackField, setShowFeedbackField] = useState(false)
  const pregeneratedFeedback = ['Response was wrong', 'Response was incomplete', 'Response was hard to understand', 'Other']
  const regeneratedFeedback = ['Better', 'Same', 'Worse', 'Other']
  const handleTextChange = (event: any): void => {
    setText(event.target.value)
  }

  const handleSubmit = (): void => {
    onSubmit(text)
    onClose()
  }

  const handleSubmitPregenerated = (text: string): void => {
    onSubmit(text)
    onClose()
  }

  const handleClickOther = (): void => {
    setShowFeedbackField(true)
  }

  return (
    <StyledFeedbackWrapper>
      <StyledFeedbackHeader>
        <Typography fontSize={18} fontWeight={400}>
          {regenerated ? 'How was this new response?' : 'Tell Us More:'}
        </Typography>
        <Box onClick={onClose} style={{ cursor: 'pointer' }}><CloseIcon fontSize="medium" /></Box>
      </StyledFeedbackHeader>
      {!regenerated &&
      <StyledPregeneratedResponseWrapper>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(pregeneratedFeedback[0]) }}>
          {pregeneratedFeedback[0]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(pregeneratedFeedback[1]) }}>
          {pregeneratedFeedback[1]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(pregeneratedFeedback[2]) }}>
          {pregeneratedFeedback[2]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleClickOther(); e.currentTarget.setAttribute('active', 'true') }}>
          {pregeneratedFeedback[3]}
        </StyledPregeneratedResponseButton>
      </StyledPregeneratedResponseWrapper>
      }
      {regenerated &&
      <StyledPregeneratedResponseWrapper>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(regeneratedFeedback[0]) }}>
          {regeneratedFeedback[0]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(regeneratedFeedback[1]) }}>
          {regeneratedFeedback[1]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleSubmitPregenerated(regeneratedFeedback[2]) }}>
          {regeneratedFeedback[2]}
        </StyledPregeneratedResponseButton>
        <StyledPregeneratedResponseButton onClick={(e) => { handleClickOther(); e.currentTarget.setAttribute('active', 'true') }}>
          {regeneratedFeedback[3]}
        </StyledPregeneratedResponseButton>
      </StyledPregeneratedResponseWrapper>
      }
      { showFeedbackField &&
      <StyledFeedbackBox>
        <TextField
          placeholder='Type feedback here'
          onChange={handleTextChange}
          value={text}
          minRows={1}
          maxRows={1}
          multiline
          sx={{ flex: 1, margin: '0px 4px 0px 0px' }}
        />
        <StyledFeedbackButton
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={text === '' || false}
        >
          Submit
        </StyledFeedbackButton>
      </StyledFeedbackBox>
      }
    </StyledFeedbackWrapper>
  )
}

export default FeedbackWidget
