import React, { useState } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'
import { v4 as uuidv4 } from 'uuid'
import { StyledChatBotScreen } from './styled'
import ChatBotScreenHeader from './ChatBotScreenHeader'
import ChatConversationSection from './ChatConversationSection'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import NewChatConfirmationModal from '../../features/NewChatButton/NewChatConfirmation'
import { getIsIframe } from '../../utils/getIsIframe'
import getForcedMobile from '../../utils/forceMobile'

const ChatBotScreen: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isIframe = getIsIframe()
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [session, setSession] = useLocalStorage<string>('session_id', '')

  const handleOnOpenConfirmation = (): void => {
    setShowConfirmationModal(true)
  }

  const handleOnCloseConfirmation = (): void => {
    setShowConfirmationModal(false)
  }

  const handleOnConfirmation = (): void => {
    setSession(`${uuidv4()}`)
    setShowConfirmationModal(false)
  }

  return (
    <StyledChatBotScreen isMobile={isMobile || forcedMobile} isIframe={isIframe}>
      {(isMobile || forcedMobile) &&
        <ChatBotScreenHeader onOpenNewChat={handleOnOpenConfirmation}/>
      }
      <ChatConversationSection session={session} onOpenNewChat={handleOnOpenConfirmation}/>
      <NewChatConfirmationModal open={showConfirmationModal} onClose={handleOnCloseConfirmation} onConfirmation={handleOnConfirmation}/>
    </StyledChatBotScreen>
  )
}

export default ChatBotScreen
