import { styled } from 'styled-components'
import theme from '../../Theme'
import Box from '@mui/material/Box'

export const StyledNewChatButton = styled(Box) <{ isMobile: boolean }>`
  color: white !important;  
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  align-content: center;
  background: linear-gradient(to bottom, ${theme.colors.primary.main} 0%, ${theme.colors.primary.secondary} 100%);
  display: ${(props) => props.isMobile ? 'none' : ''}
`
