import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import CrossIcon from '../../../assets/Cross.svg'
import { StyledConfirmationButton, StyledConfirmationHeader, StyledConfirmationWrapper, StyledConfirmationMessage, StyledButtonBox, StyledNewChatIcon } from './styled'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import getForcedMobile from '../../../utils/forceMobile'

interface NewChatConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirmation: () => void
}

const NewChatConfirmationModal: React.FC<NewChatConfirmationModalProps> = ({ open, onClose, onConfirmation }) => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleConfirmation = (): void => {
    onConfirmation()
  }

  const handleClose = (): void => {
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <StyledConfirmationWrapper isMobile={isMobile || forcedMobile}>
        <StyledConfirmationHeader>
          <Typography fontSize={20} fontWeight={700}>
            Start New Chat
          </Typography>
          <Box onClick={onClose}><img src={CrossIcon} alt='cross icon' /></Box>
        </StyledConfirmationHeader>
        <StyledNewChatIcon>
          <AddCommentOutlinedIcon fontSize="large"/>
        </StyledNewChatIcon>
        <StyledConfirmationMessage>
          Starting a new chat will delete your current chat history.
        </StyledConfirmationMessage>
        <StyledButtonBox>
          <StyledConfirmationButton
            variant='outlined'
            onClick={handleClose}
            color='primary'
          >
            Resume Current Chat
          </StyledConfirmationButton>
          <StyledConfirmationButton
            variant='contained'
            onClick={handleConfirmation}
          >
            Start New Chat
          </StyledConfirmationButton>
        </StyledButtonBox>
      </StyledConfirmationWrapper>
    </Modal>
  )
}

export default NewChatConfirmationModal
