import { styled } from 'styled-components'
import theme from '../../../Theme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export const StyledConfirmationWrapper = styled(Box) <{ isMobile: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.isMobile ? `${theme.spacing(118)}` : `${theme.spacing(132.5)}`};
  max-width: calc(100% - ${theme.spacing(8)});
  background: white;
  border-radius: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(4)};
`

export const StyledConfirmationHeader = styled(Box)`
  display: flex;
  align-items: center; 
  justify-content: space-between;
`
export const StyledConfirmationButton = styled(Button)`
  background: ${(props) =>
    props.variant !== undefined && props.variant === 'outlined'
      ? '#ffffff'
      : `${theme.colors.button.primary} !important`};
  color: ${(props) =>
    props.variant !== undefined && props.variant === 'outlined'
      ? `${theme.colors.button.primary} !important`
      : ''};
  border: ${(props) =>
    props.variant !== undefined && props.variant === 'outlined'
      ? `1px solid ${theme.colors.button.primary} !important`
      : ''};
  align-self: flex-end;
  text-transform: capitalize;
  padding-left: ${theme.spacing(7.5)};
  padding-right: ${theme.spacing(7.5)};
  border-radius: none
`

export const StyledConfirmationMessage = styled(Box)`
text-align: center;
padding-top: ${theme.spacing(4)};
padding-bottom: ${theme.spacing(5)};
`

export const StyledButtonBox = styled(Box)`
display: flex;
justify-content: space-between;
gap: 20px;
`

export const StyledNewChatIcon = styled(Box)`
text-align: center;
color: ${theme.colors.button.primary} !important;
`
