import React from 'react'
import Markdown from 'markdown-to-jsx'
import CustomTable from '../components/CustomTable'

export const formatBotMessage = (text: string): React.ReactNode => {
  return (
    <Markdown
      className='markdown-content'
      // eslint-disable-next-line react/no-children-prop
      children={`${text}`}
      options={{
        overrides: {
          table: {
            component: CustomTable
          },
          a: {
            props: {
              target: '_blank'
            }
          }
        }
      }}
    />
  )
}
