import React from 'react'
import { StyledNewChatButton } from './styled'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import getForcedMobile from '../../utils/forceMobile'

interface NewChatButtonProps {
  handleClick: () => void
}

const NewChatButton: React.FC<NewChatButtonProps> = ({ handleClick }): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <StyledNewChatButton onClick={handleClick} className='newChatButton' title="Start a New Chat" isMobile={isMobile || forcedMobile} >
      <AddCommentOutlinedIcon fontSize="medium" style={{ marginTop: '5px' }}/>
    </StyledNewChatButton>
  )
}

export default NewChatButton
