import styled from 'styled-components'
import theme from '../../Theme'
import Box from '@mui/material/Box'

export const StyledHeaderWrapper = styled.header`
  width: 100%;
  border-bottom: 2px solid ${theme.colors.border.light};
  position: sticky;
  height: ${theme.spacing(14)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledArrowDownIcon = styled(Box)`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.spacing(4.5)};
  background-color: black;
  clip-path: polygon(50% 0%, 0% 50%, 100% 50%);
  transform: translateY(-15%) rotate(180deg);
  transition: transform 0.2s;
`

export const StyledLogos = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2.5)};
`

export const StyledLoggedInWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2.5)};
  margin-right: ${theme.spacing(5)};
`
