import React from 'react'
import { StyledQuestionButton } from './styled'

interface NextQuestionProps {
  question: string
  handleClick: (val: string) => Promise<any>
}

const NextQuestion: React.FC<NextQuestionProps> = ({ question, handleClick }): JSX.Element => {
  return (
    <StyledQuestionButton variant='outlined' onClick={() => { handleClick(question).catch(console.error) }}>
      {question}
    </StyledQuestionButton>
  )
}

export default NextQuestion
