export const getCurrentTime = (): string => {
  const currentTime = new Date()
  const hours = currentTime.getHours()
  const minutes = currentTime.getMinutes()
  const amPm = hours >= 12 ? 'PM' : 'AM'

  const displayHours = hours % 12 || 12 // eslint-disable-line @typescript-eslint/strict-boolean-expressions

  const timeString = `${displayHours}:${minutes < 10 ? '0' : ''}${minutes} ${amPm}`
  return timeString
}

export const getCurrentDay = (): string => {
  const currentTime = new Date()
  const day = currentTime.getDate()
  const month = currentTime.getMonth() + 1

  const dayString = `${month}/${day}`
  return dayString
}
