import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { microsoftUserLoginAPI } from '../../../../utils/api'
import {
  StyledLoginFormWrapper,
  StyledHeading,
  StyledWelcomeMessage,
  StyledForm
} from './styled'
import { ROUTES } from '../../../../consts/Routes'
import CustomSnackBar from '../../../../components/CustomSnackBar'
import { SnackBarMessageType } from '../../../../consts/SnackbarMessageTypes'
import Button from '@mui/material/Button'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import { useMsal } from '@azure/msal-react'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { loginRequest, setToken } from '../../../../config/msAuthConfig'

const LoginForm: React.FC = (): JSX.Element => {
  const { instance } = useMsal()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [isError, setIsError] = useState(false)

  const loginPopup = async (msalInstance: IPublicClientApplication): Promise<any> => {
    try {
      const res = await msalInstance.loginPopup(loginRequest)
      const params = {
        token: res.accessToken
      }
      const loginData = await microsoftUserLoginAPI(params)
      const idTokenClaims = res.idTokenClaims
      setToken(loginData?.payload?.token)
      localStorage.setItem('token', loginData?.payload?.token)
      localStorage.setItem('internal_user', 'true')
      localStorage.setItem('session_id', `"${uuidv4()}"`)
      localStorage.setItem('platform', 'standalone')
      localStorage.setItem('user_name', (idTokenClaims as any).name)
      localStorage.setItem('user_email', (idTokenClaims as any).preferred_username)
      navigate(ROUTES.ADMIN_LOGIN)
    } catch (error) {
      setErrorMessage('Login failed. Please check your credentials.')
      setIsError(true)
      localStorage.clear()
    }
  }

  return (
    <>
      <StyledLoginFormWrapper>
        <StyledHeading>Login</StyledHeading>
        <StyledWelcomeMessage>Welcome to the Buyers Edge Platform Chat Bot Beta</StyledWelcomeMessage>
        <StyledForm >
          <Button variant='contained' startIcon={<MicrosoftIcon />} sx={{ width: '100%' }} onClick={() => { loginPopup(instance).catch(console.error) }}>Log In with Microsoft</Button>
        </StyledForm >
      </StyledLoginFormWrapper >
      <CustomSnackBar isOpen={isError} onClose={setIsError} message={errorMessage} duration={5000} messageType={SnackBarMessageType.ERROR} />
    </>
  )
}

export default LoginForm
