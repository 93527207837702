const theme = {
  colors: {
    primary: {
      main: '#CF3F7C',
      secondary: '#314175'
    },
    text: {
      main: '#fff',
      secondary: '#000'
    },
    background: {
      primary: '#357AF1'
    },
    border: {
      light: '#D7D8D9',
      dark: '#D9D9D9'
    },
    button: {
      primary: '#CF3F7C',
      secondary: '#F8F8F8'
    }
  },
  spacing: (factor: number) => `${4 * factor}px`
}

export default theme
