import React, { useEffect, useState } from 'react'
import BepLogo from '../../../../assets/bepLogo.png'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SampleManufacturerQuestions, SampleOperatorQuestions } from '../../../../consts/SampleQuestions'
import SampleQuestion from './../SampleQuestion'
import { StyledInitialScreen, StyledBackgroundImage } from './styled'
import getOrientation from '../../../../utils/getOrientation'
import getForcedMobile from '../../../../utils/forceMobile'

interface InitialScreenProps {
  handleSampleQuestion: (arg: string) => void
}

const InitialScreen: React.FC<InitialScreenProps> = ({ handleSampleQuestion }): JSX.Element => {
  const theme = useTheme()
  const isPortrait = getOrientation()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const platformId = localStorage.getItem('platform_id')
  const [splitPlatformId, setSplitPlatformId] = useState<string[]>([])

  useEffect(() => {
    if (platformId != null) {
      setSplitPlatformId(platformId.split('-'))
    }
  }, [])

  return (
    <StyledInitialScreen isMobile={isMobile || isPortrait || forcedMobile}>
      <StyledBackgroundImage>
        <img src={BepLogo} alt='BG' height={isMobile ? '81px' : '162px'} />
      </StyledBackgroundImage>
      <Grid container spacing={isMobile || isPortrait || forcedMobile ? '20px' : '12px'} style={{ marginBottom: '24px' }}>
        {splitPlatformId[0] === 'MA' && SampleManufacturerQuestions?.map((question, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <SampleQuestion
              key={index}
              text={question.text}
              sampleQuestionClick={() => { handleSampleQuestion(question.text) }}
            />
          </Grid>
        ))}
        {splitPlatformId[0] === 'CL' && SampleOperatorQuestions?.map((question, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <SampleQuestion
              key={index}
              text={question.text}
              sampleQuestionClick={() => { handleSampleQuestion(question.text) }}
            />
          </Grid>
        ))}
      </Grid>
    </StyledInitialScreen>
  )
}

export default InitialScreen
