import { styled } from 'styled-components'
import Box from '@mui/material/Box'
import theme from '../../../../Theme'

export const StyledUserMessageWrapper = styled(Box) <{ isMobile: boolean }>`
width: 100%;
max-width: ${(props) => props.isMobile ? '100%' : '100%'};
justify-self: flex-end;
margin-left: auto;
`

export const StyledUserMessage = styled(Box)`
 background: ${theme.colors.background.primary}; 
 color: ${theme.colors.text.main};
 padding: ${theme.spacing(3)}; 
 border-radius: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
`

export const StyledUserTime = styled(Box)`
 text-align: end; 
 margin-top: ${theme.spacing(1.25)}; 
 margin-bottom: ${theme.spacing(4)}; 
 font-size: ${theme.spacing(2.5)}; 
 font-weight: bold 
`
