import React, { useState } from 'react'
import { StyledCustomTableWrapper, StyledCustomTable } from './styled'
import CustomButton from '../CustomButton'

interface CustomTableProps {
  children: JSX.Element[]
}

const CustomTable: React.FC<CustomTableProps> = ({ children }): JSX.Element => {
  const [showExtraRows, setShowExtraRows] = useState(false)
  const tableBody = children[1]
  const tableRows = tableBody.props.children
  const showButton = tableRows.length > 10
  const firstTenRows = tableRows.slice(0, 10)
  const extraRows = tableRows.slice(11, tableRows.length)

  return (
    <StyledCustomTableWrapper>
      <StyledCustomTable>
        {children[0]}
        <tbody>
          {firstTenRows}
          {showExtraRows && extraRows}
        </tbody>
      </StyledCustomTable>
      {showButton &&
      <CustomButton
        onClick={() => { setShowExtraRows(!showExtraRows) }}
        disabled={false}
        label={showExtraRows ? 'Show Less' : 'Show More'}
      />
      }
    </StyledCustomTableWrapper>
  )
}

export default CustomTable
