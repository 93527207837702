import styled from 'styled-components'
import Box from '@mui/material/Box'
import { Table } from '@mui/material'

export const StyledCustomTableWrapper = styled(Box)`
`

export const StyledCustomTable = styled(Table)`
    border-collapse: collapse;
    background: white;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;

    & thead {
      font-size: 12px;
    }

    & tbody {
      font-size: 14px;
    }
`
