import { styled } from 'styled-components'
import Box from '@mui/material/Box'
import theme from '../../../../Theme'

export const StyledLoginFormWrapper = styled(Box)` 
 display: flex; 
 align-items: center; 
 justify-content: center;
 flex-direction: column;
`

export const StyledHeading = styled.h2`
 font-weight: 700;
 margin-top: ${theme.spacing(12.5)};
`

export const StyledWelcomeMessage = styled.p`
font-size: ${theme.spacing(3.5)};
margin: ${theme.spacing(5)} ${theme.spacing(0)};
`

export const StyledForm = styled.form`
width: ${theme.spacing(100)};
@media (max-width: 768px) {
width: ${theme.spacing(80)};
  }
`
