import React from 'react'
import { StyledLoaderWrapper, StyledLoader } from './styled'

const CustomLoader: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledLoaderWrapper>
        <StyledLoader />
      </StyledLoaderWrapper>
    </>
  )
}

export default CustomLoader
