import { styled } from 'styled-components'
import theme from '../../Theme'
import Box from '@mui/material/Box'

export const StyledLoaderWrapper = styled(Box)`
height: calc(100vh - ${theme.spacing(20)}); 
display: flex; 
align-items: center; 
justify-content: center;
`

export const StyledLoader = styled.span`
  width: ${theme.spacing(12)};
  height: ${theme.spacing(12)};
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: ${theme.spacing(1)} solid;
  border-color: #CF3F7C #CF3F7C transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

&::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: ${theme.spacing(1)} solid;
  border-color: #007CF9 #007CF9 transparent;
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
`
