import React from 'react'
import ChatHeader from '../../components/ChatHeader'
import ChatBotScreen from '../../components/ChatBotScreen'
import { StyledLayoutWrapper } from './styled'
import { getIsIframe } from '../../utils/getIsIframe'

const ChatBotLayout: React.FC = () => {
  const isIframe = getIsIframe()

  return (
    <StyledLayoutWrapper>
      {!isIframe &&
      <ChatHeader />
      }
      <ChatBotScreen />
    </StyledLayoutWrapper >
  )
}

export default ChatBotLayout
