import React from 'react'
import Logo from '../../assets/Logo.svg'
import { StyledLoginHeaderWrapper, StyledImage } from './styled'

const LoginHeader: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledLoginHeaderWrapper>
        <StyledImage src={Logo} alt="App Logo" />
      </StyledLoginHeaderWrapper >
    </>
  )
}

export default LoginHeader
