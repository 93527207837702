import React, { useEffect, useState } from 'react'
import { StyledHeaderWrapper, StyledLogos, StyledLoggedInWrapper } from './styled'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AppIcon from '../../assets/AppsIcon.svg'
import AppLogo from '../../assets/BuyersEdgeLogo.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../consts/Routes'
import { checkOperatorPlatform } from '../../utils/checkOperatorPlatform'

const ChatHeader: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & SVGSVGElement | null>(null)
  const [platformName, setPlatformName] = useState<string>('')
  const internalUser = localStorage.getItem('internal_user')
  const navigate = useNavigate()
  const open = Boolean(anchorEl)

  const manufactureroperatorName = localStorage.getItem('manufacturer_operator_name')
  const platform = localStorage.getItem('platform_id')

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handlePlatform = (): void => {
    setAnchorEl(null)
    navigate(`${ROUTES.ADMIN_LOGIN}?platform=${platformName}`)
  }

  const handleLogout = (): void => {
    setAnchorEl(null)
    localStorage.clear()
    navigate(ROUTES.HOME)
  }

  useEffect(() => {
    if (platform !== null) {
      checkOperatorPlatform(platform) ? setPlatformName('Operator') : setPlatformName('Manufacturer')
    }
  }, [platform])

  return (
    <StyledHeaderWrapper>
      <StyledLogos>
        <img src={AppIcon} alt="icon" />
        <img src={AppLogo} alt="logo" />
      </StyledLogos>
      <StyledLoggedInWrapper>
        <h5>{manufactureroperatorName}</h5>
        <ArrowDropDownIcon onClick={handleClick} />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {internalUser === 'true' &&
          <MenuItem onClick={handlePlatform}>Change {platformName}</MenuItem>
          }
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </Menu>
      </StyledLoggedInWrapper>
    </StyledHeaderWrapper>
  )
}

export default ChatHeader
