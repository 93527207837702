/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import type { ChatListT, ReactionT, FeedbackT } from '../types'
import { formatBotMessage } from '../../../../utils/formatBotMessage'
import { sendResponseReaction } from '../../../../utils/api'
import FeedbackWidget from './Feedback/index'
import CustomSnackBar from '../../../CustomConversationSnackBar'
import { ThumbDownOutlined, ThumbUp, ThumbUpOutlined, ThumbDown, ContentCopyOutlined, LoopOutlined } from '@mui/icons-material'
import { SnackBarMessageType } from '../../../../consts/SnackbarMessageTypes'
import {
  StyledBotMessage,
  StyledBotMessageWrapper,
  StyledFeedbackWrapper,
  StyledReactionWrapper,
  StyledTimeStamp,
  StyledIconButton,
  StyledBotIcon,
  StyledMarkdownSection
} from './styled'
import getOrientation from '../../../../utils/getOrientation'
import BepIconWhite from '../../../../assets/bepIconWhite.png'
import getForcedMobile from '../../../../utils/forceMobile'

interface BotMessageProps {
  message: ChatListT
  chatRecordId?: string
  isRegenerated: boolean
  isFeedbackSubmit: (val: boolean) => void
  handleRegenerate: (val: string) => Promise<any>
  userMessage: ChatListT
}

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
    left: '-36px !important'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    left: '36px !important',
    borderRadius: '4px',
    fontSize: '16px',
    padding: '12px 8px',
    width: 'fit-content',
    height: '42px'
  }
}))

const BotMessage: React.FC<BotMessageProps> = ({ message, userMessage, chatRecordId, isRegenerated, isFeedbackSubmit, handleRegenerate }) => {
  const theme = useTheme()
  const isPortrait = getOrientation()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [showThumbsUp, setShowThumbsUp] = useState<boolean>(false)
  const [showThumbsDown, setShowThumbsDown] = useState<boolean>(false)
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(isRegenerated)
  const [showfeedbackSnackbar, setShowFeedbackSnackbar] = useState<boolean>(false)
  const [showCopySnackbar, setShowCopySnackbar] = useState<boolean>(false)

  const SubmitReaction = async (chatRecordId: string | undefined, feedback: ReactionT | FeedbackT): Promise<void> => {
    try {
      await sendResponseReaction(chatRecordId, feedback)
    } catch (err: any) {
      console.log('Err', err)
    }
  }

  const handleReaction = async (reaction: string): Promise<void> => {
    if (reaction === 'up') {
      setShowThumbsUp(reaction === 'up')
      setShowThumbsDown(false)
      setShowFeedbackModal(false)
      isFeedbackSubmit(true)
      await SubmitReaction(chatRecordId, { rating: true })
    } else if (reaction === 'down') {
      setShowThumbsUp(false)
      setShowThumbsDown(reaction === 'down')
      setShowFeedbackModal(true)
      isFeedbackSubmit(false)
      await SubmitReaction(chatRecordId, { rating: false })
    } else {
      setShowFeedbackModal(reaction === 'feedback')
    }
  }

  const handleOnClose = (): void => {
    setShowFeedbackModal(false)
  }

  const handleOnSubmit = async (text: string): Promise<void> => {
    if (isRegenerated) {
      if (text === 'Better') {
        setShowThumbsUp(true)
      } else if (text === 'Worse') {
        setShowThumbsDown(true)
      } else {
        // Do nothing
      }
    }
    setShowFeedbackSnackbar(true)
    await SubmitReaction(chatRecordId, { message: text })
    setShowFeedback(true)
  }

  const handleOnCopy = async (): Promise<void> => {
    try {
      setShowCopySnackbar(true)
      await navigator.clipboard.writeText(message.text)
    } catch (err: any) {
      console.log(err)
    }
  }

  return (
    <>
      <StyledBotMessageWrapper key={chatRecordId} isMobile={isMobile || isPortrait || forcedMobile}>
        <StyledBotMessage>
          <StyledBotIcon>
            <img src={BepIconWhite} width={'13px'}/>
          </StyledBotIcon>
          <StyledMarkdownSection>
            {formatBotMessage(message?.text)}
          </StyledMarkdownSection>
          {chatRecordId !== undefined && (
            <StyledFeedbackWrapper>
              {!showThumbsUp &&
              <StyledIconButton size="small" title="Thumbs Up" disableRipple={true} onClick={() => { handleReaction('up').catch(console.error) }}>
                <ThumbUpOutlined fontSize="small" />
              </StyledIconButton>
              }
              {showThumbsUp &&
              <StyledIconButton size="small" title="Thumbs Up" disableRipple={true}>
                <ThumbUp fontSize="small" />
              </StyledIconButton>
              }
              {!showThumbsDown &&
              <StyledIconButton size="small" title="Thumbs Down" disableRipple={true} onClick={() => { handleReaction('down').catch(console.error) }}>
                <ThumbDownOutlined fontSize="small" />
              </StyledIconButton>
              }
              {showThumbsDown &&
              <StyledIconButton size="small" title="Thumbs Down" disableRipple={true}>
                <ThumbDown fontSize="small" />
              </StyledIconButton>
              }
              <StyledIconButton size="small" title="Copy" disableRipple={true} onClick={handleOnCopy}>
                <ContentCopyOutlined fontSize="small" />
              </StyledIconButton>
              <StyledIconButton size="small" title="Regenerate Answer" disableRipple={true} onClick={() => { handleRegenerate(userMessage.text).catch(console.error) }}>
                <LoopOutlined fontSize="small" />
              </StyledIconButton>
              <CustomSnackBar isOpen={showCopySnackbar} onClose={setShowCopySnackbar} message={'Message Copied!'} duration={3000} messageType={SnackBarMessageType.SUCCESS} />
            </StyledFeedbackWrapper>
          )}
          {
            showFeedbackModal && (
              <FeedbackWidget regenerated={isRegenerated} open={showFeedbackModal} onClose={handleOnClose} onSubmit={handleOnSubmit} />
            )
          }
        </StyledBotMessage>

        <StyledReactionWrapper>
          <StyledTimeStamp>
            {message.timeStamp}
            {showFeedback && (
              <>
                <CustomSnackBar isOpen={showfeedbackSnackbar} onClose={setShowFeedbackSnackbar} message={'Thank you! Your feedback was sent.'} duration={3000} messageType={SnackBarMessageType.SUCCESS} />
              </>
            )}
          </StyledTimeStamp>
        </StyledReactionWrapper>
      </StyledBotMessageWrapper >
    </>
  )
}

export default BotMessage
