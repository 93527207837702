import { useState, useEffect } from 'react'

const getForcedMobile = (): boolean => {
  const [isForceMobile, setIsForceMobile] = useState(localStorage.getItem('forceMobileView') === 'true' || false)

  const handleForceMobile = (event: MessageEvent): void => {
    if (event.data === 'forceMobileViewTrue') {
      setIsForceMobile(true)
    }
    if (event.data === 'forceMobileViewFalse') {
      setIsForceMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('message', (event) => { handleForceMobile(event) })
    return () => {
      window.removeEventListener('message', (event) => { handleForceMobile(event) })
    }
  }, [])

  return isForceMobile
}

export default getForcedMobile
