import React from 'react'
import BepIconWhite from '../../../assets/bepIconWhite.png'
import {
  StyledHeader,
  StyledIconButton,
  StyledMesssageImage,
  StyledMesssageImageWrapper,
  StyledButtonBox
} from './styled'
import AddCommentOutlined from '@mui/icons-material/AddCommentOutlined'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import getForcedMobile from '../../../utils/forceMobile'

interface ChatConversationHeaderProps {
  onOpenNewChat: () => void
}

const ChatBotScreenHeader: React.FC<ChatConversationHeaderProps> = ({ onOpenNewChat }): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledHeader isMobile={isMobile || forcedMobile}>
      <StyledButtonBox>
        <StyledIconButton size="large" title='Start New Chat' onClick={onOpenNewChat}>
          <AddCommentOutlined fontSize="medium"/>
        </StyledIconButton>
      </StyledButtonBox>
      <StyledMesssageImageWrapper>
        <StyledMesssageImage
          isMobile={isMobile || forcedMobile}
          src={BepIconWhite}
          alt='Message Icon'
        />
      </StyledMesssageImageWrapper>
    </StyledHeader>
  )
}

export default ChatBotScreenHeader
