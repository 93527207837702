import { styled } from 'styled-components'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import theme from '../../../../Theme'

export const StyledSampleQuestionWrapper = styled(Box) <{ isMobile: boolean }>`
  border-radius: ${theme.spacing(1.5)};
  padding: ${(props) => props.isMobile ? `${theme.spacing(3)}` : `${theme.spacing(4)}`};
  border: 1px solid ${theme.colors.border.light};
  box-shadow: rgba(0, 0, 0, 0.25) 1.95px 1.95px 2.6px;
`

export const StyledSampleQuestion = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledContent = styled(Typography)`
  font-size: ${theme.spacing(4)} !important;
  font-weight: 500 !important;
  line-height: 1 !important;
`

export const StyledSendIcon = styled.img`
  width: ${theme.spacing(6)};
  height: ${theme.spacing(5.25)};
`
