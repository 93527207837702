import axios from 'axios'
import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token: string | null = localStorage.getItem('token')

    if (token !== null) {
      config.headers.authorization = `${token}`
    }
    return config
  },
  async (error: AxiosError) => {
    return await Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.clear()
      window.location.href = '/'
    }
    return await Promise.reject(error)
  }
)

export default axiosInstance
