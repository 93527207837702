import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

interface CustomAutoCompleteProps {
  options: Array<Record<string, any>>
  value: Record<string, any> | null
  onChange: (event: any, newValue: any) => void
  inputValue?: string
  onInputChange?: (event: any, newInputValue: string) => void
  getOptionLabel: (option: Record<string, any>) => string
  placeholder: string
  disabled?: boolean
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  options,
  value,
  onChange,
  inputValue,
  onInputChange,
  getOptionLabel,
  placeholder,
  disabled = false
}): JSX.Element => {
  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  )
}

export default CustomAutoComplete
