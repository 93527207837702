import { styled } from 'styled-components'
import theme from '../../../../../Theme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export const StyledFeedbackWrapper = styled(Box)`
  background: #fff;
  border: 1px solid #D3D8E0;
  margin-top: ${theme.spacing(2)};
  border-radius: ${theme.spacing(2)};
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
`

export const StyledFeedbackButton = styled(Button)`
  background: ${(props) =>
    props.disabled !== undefined && props.disabled
      ? `rgba(${theme.colors.primary.main}, 0.7) !important`
      : `${theme.colors.primary.main} !important`};
  color: white;
  align-self: flex-end;
  text-transform: capitalize;
  border-radius: none;
  padding: 15px !important;
`

export const StyledFeedbackHeader = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(3)};
`

export const StyledFeedbackBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(3)};
`

export const StyledPregeneratedResponseButton = styled(Box)`
  background: #E7EAED;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.25s ease-in-out;

  &:hover {
    background: #007CF9;
    color: white;
  }

  &[active='true'] {
    background: #007CF9;
    color: white;
  }
`

export const StyledPregeneratedResponseWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0px 12px 12px 12px;
  overflow-x: auto;
  text-wrap: nowrap;
`
