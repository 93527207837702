import { styled } from 'styled-components'
import theme from '../../Theme'
import Box from '@mui/material/Box'

export const StyledLoginHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center; 
  justify-content: center; 
  box-shadow: ${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1.5)} rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 10;
  background: white;
`

export const StyledImage = styled.img`
  width: ${theme.spacing(20)}; 
  height: ${theme.spacing(20)};
`
