import { useState, useEffect } from 'react'

const getOrientation = (): boolean => {
  const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight)

  const handleOrientationChange = (): void => {
    setIsPortrait(window.innerWidth < window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange)
    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  }, [])

  return isPortrait
}

export default getOrientation
