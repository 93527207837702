import React from 'react'
import Box from '@mui/material/Box'
import SendIconActive from '../../../../assets/SendIconActive.svg'
import {
  StyledContent,
  StyledSampleQuestion,
  StyledSampleQuestionWrapper,
  StyledSendIcon
} from './styled'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import getForcedMobile from '../../../../utils/forceMobile'

interface SampleQuestionProps {
  text: string
  sampleQuestionClick: (arg: string) => void
}

const SampleQuestion: React.FC<SampleQuestionProps> = ({ text, sampleQuestionClick }): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledSampleQuestionWrapper isMobile={isMobile || forcedMobile} onClick={() => { sampleQuestionClick(text) }}>
      <StyledSampleQuestion>
        <Box marginRight={isMobile || forcedMobile ? '8px' : '0px'}>
          <StyledContent>{text}</StyledContent>
        </Box>
        <StyledSendIcon src={SendIconActive} alt='Send icon' />
      </StyledSampleQuestion>
    </StyledSampleQuestionWrapper>
  )
}

export default SampleQuestion
