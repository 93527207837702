import React from 'react'
import LoginForm from './Components/LoginForm'
import { StyledFormWrapper } from './styled'

const LoginFeature: React.FC = (): JSX.Element => {
  return (
    <StyledFormWrapper >
      <LoginForm />
    </StyledFormWrapper>
  )
}

export default LoginFeature
