import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import CustomtabPanel from '../../components/CustomTabPanel'
import type { ManufactureListType, OperatorListType } from './types'
import ManufacturerTab from './ManufacturerTab'
import OperatorTab from './OperatorTab'
import theme from '../../Theme'
import { useLocation } from 'react-router-dom'
import { tabLabels } from '../../consts/TabLabels'

interface AdminLoginFeatureProps {
  manufacturerList: ManufactureListType[]
  operatorList: OperatorListType[]
}

const AdminLoginFeature: React.FC<AdminLoginFeatureProps> = ({ manufacturerList, operatorList }): JSX.Element => {
  const location = useLocation()
  const [value, setValue] = useState(tabLabels[1])

  useEffect(() => {
    const platform = new URLSearchParams(location.search).get('platform')
    if (tabLabels.includes(platform ?? '')) {
      setValue(platform ?? tabLabels[1])
    }
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setValue(newValue)
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      height={'calc(100vh - 80px)'}
      sx={{ background: '#f8f8f8' }}
    >
      <Typography variant='h4' fontWeight={'bold'} sx={{ marginBottom: '10px', marginTop: '50px', textAlign: 'center' }}>Admin Login</Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            backgroundColor: `${theme.colors.primary.main}`
          }
        }}
      >
        {tabLabels.map((label, index) => (
          <Tab key={index} label={label} sx={{ color: `${theme.colors.primary.main}` }} value={label} />
        ))}
      </Tabs>
      <CustomtabPanel value={value} index={value}>
        {value === 'Manufacturer' ? <ManufacturerTab manufacturerList={manufacturerList} /> : <OperatorTab operatorList={operatorList} />}
      </CustomtabPanel>
    </Box>
  )
}

export default AdminLoginFeature
