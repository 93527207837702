import React from 'react'
import Button from '@mui/material/Button'
import theme from '../../Theme'

interface CustomButtonProps {
  onClick: () => void
  disabled: boolean
  label: string
}
const CustomButton: React.FC<CustomButtonProps> = ({ onClick, disabled, label }): JSX.Element => {
  return (
    <Button
      variant="contained"
      sx={{
        marginTop: theme.spacing(2.5),
        backgroundColor: theme.colors.primary.main,
        color: 'white'
      }}
      onClick={onClick}
      disabled={disabled}
      fullWidth
    >
      {label}
    </Button>
  )
}

export default CustomButton
