import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import type { OperatorListType } from '../types'
import { ROUTES } from '../../../consts/Routes'
import { getAllOperatorsByInputAPI } from '../../../utils/api'
import CustomAutoComplete from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import CustomSnackBar from '../../../components/CustomSnackBar'
import { SnackBarMessageType } from '../../../consts/SnackbarMessageTypes'

interface OperatorTabProps {
  operatorList: OperatorListType[]
}

const OperatorTab: React.FC<OperatorTabProps> = ({ operatorList }): JSX.Element => {
  const navigate = useNavigate()
  const [operators, setOperators] = useState(operatorList)
  const [selectedOperator, setSelectedOperator] = useState<OperatorListType | null>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [isLoadingOperators, setIsLoadingOperators] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('')
  const loadingMessage = 'Loading additional operators...'

  const handleInputChange = (event: any, newInputValue: string): void => {
    setInputValue(newInputValue)
  }

  const handleAutocompleteChange = (event: any, newValue: OperatorListType | null): void => {
    setSelectedOperator(newValue)
    setIsButtonDisabled(newValue === null)
  }

  const handleButtonClick = (): void => {
    if (selectedOperator !== null) {
      localStorage.setItem('platform_id', selectedOperator.operator_platform_id)
      localStorage.setItem('manufacturer_operator_name', selectedOperator.operator)
      navigate(ROUTES.CHAT)
    }
  }

  const fetchSelectedOperatorsFromAPI = async (inputValue: string): Promise<void> => {
    if (inputValue !== '' && selectedOperator === null) {
      setIsLoadingOperators(true)
      try {
        const response = await getAllOperatorsByInputAPI(inputValue, 50)
        if (response.payload !== null) {
          console.log('closing snackbar')
          setIsLoadingOperators(false)
          setOperators(response?.payload)
        } else {
          console.log('closing snackbar')
          setIsLoadingOperators(false)
          setOperators([])
        }
      } catch (error) {
        console.error('API Error:', error)
      }
    } else {
      setOperators(operatorList)
    }
  }

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedInputValue(inputValue)
    }, 1000)

    return () => {
      clearTimeout(debounceTimer)
    }
  }, [inputValue])

  useEffect(() => {
    if (debouncedInputValue !== '') {
      fetchSelectedOperatorsFromAPI(debouncedInputValue).catch((error) => {
        console.error('API Error:', error)
      })
    }
  }, [debouncedInputValue])

  return (
    <>
      <Box width={'100%'} mb={2} mt={2}>
        <CustomAutoComplete
          options={operators}
          getOptionLabel={(operators) => `${operators.operator} ${operators.operator_platform_id}`}
          value={selectedOperator}
          onChange={handleAutocompleteChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          placeholder="Select Operator..."
        />
      </Box>
      <CustomButton
        onClick={handleButtonClick}
        disabled={isButtonDisabled}
        label="Select Operator"
      />
      <CustomSnackBar isOpen={isLoadingOperators} onClose={setIsLoadingOperators} message={loadingMessage} duration={30000} messageType={SnackBarMessageType.INFO}/>
    </>
  )
}

export default OperatorTab
