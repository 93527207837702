import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
h1,h2,h3,h4,h5 {
  margin-bottom: 4px;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

  body {
    font-family: Helvetica;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .markdown-content {
    line-height: 23px !important;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #757575 transparent;
  }

  .markdown-content ol, ul {
    list-style-position: inside;
    padding-left: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .markdown-content li + li {
    margin-top: 4px;
  }

  .markdown-content li p {
    display: inline;
  }

  .markdown-content th {
    background-color: #CF3F7C;
    color: white;
    font-weight: normal;
  }

  .markdown-content th, td {
    text-align: left;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ddd;
  }

  .markdown-content > table {
    border-collapse: collapse;
    background: white;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
  }
`

export default GlobalStyles
