import { styled } from 'styled-components'
import theme from '../../../../Theme'
import Box from '@mui/material/Box'

export const StyledInitialScreen = styled(Box) <{ isMobile: boolean }>`
  height: 100%; 
  display: flex; 
  justify-content: space-between; 
  flex-direction: column;
  margin: ${(props) => props.isMobile ? `${theme.spacing(3)}` : `${theme.spacing(4)} ${theme.spacing(6)}`};
`
export const StyledBackgroundImage = styled(Box)`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`
