import React from 'react'
import { Outlet } from 'react-router-dom'
import { StyledLayoutWrapper } from './styled'
import LoginHeader from '../../components/LoginHeader'

const LoginAndManufactureLayout: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledLayoutWrapper>
        <div>
          <LoginHeader />
        </div>
        <Outlet />
      </StyledLayoutWrapper>
    </>
  )
}

export default LoginAndManufactureLayout
