import { styled } from 'styled-components'
import theme from '../../Theme'
import Box from '@mui/material/Box'

export const StyledChatBotScreen = styled(Box) <{ isMobile: boolean, isIframe: boolean }>`  
  border: 1px solid ${theme.colors.border.light};
  background: white;
  height: ${(props) => props.isIframe ? `calc(100vh - ${theme.spacing(4)})` : `calc(100svh - ${theme.spacing(18)})`};
  border-radius: ${theme.spacing(1.5)}; 
  margin: ${`${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(4)}`};
`
