import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './Theme'
import GlobalStyles from './GlobalStyles'
import AppRoutes from './pages'
import { type PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'

interface AppProps {
  instance: PublicClientApplication
}

const App: React.FC<AppProps> = ({ instance }): JSX.Element => (
  <MsalProvider instance={instance}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppRoutes />
    </ThemeProvider>
  </MsalProvider>

)

export default App
