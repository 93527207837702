import { ROUTES } from '../../consts/Routes'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export interface AuthType {
  authed: boolean
}

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token')
  if (token !== null) return true
  return false
}

export const useAuth = (): AuthType => {
  return { authed: isAuthenticated() }
}
const ProtectedRoutes: React.FC = (): JSX.Element => {
  const { authed } = useAuth()
  return authed ? <Outlet /> : <Navigate to={ROUTES.HOME} />
}

export default ProtectedRoutes
