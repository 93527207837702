import React from 'react'
import Box from '@mui/material/Box'
import { StyledCustomTabPanelWrapper } from './styled'

interface CustomTabPanelProps {
  children: JSX.Element
  value: string
  index: string
}

const CustomtabPanel: React.FC<CustomTabPanelProps> = ({ children, value, index }): JSX.Element => {
  return (
    <StyledCustomTabPanelWrapper
      id={`tabpanel-${index}`}
      hidden={value !== index}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p={2} mb={2}>
          {children}
        </Box>
      )}
    </StyledCustomTabPanelWrapper>
  )
}

export default CustomtabPanel
