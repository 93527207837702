import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import type { ManufactureListType } from '../types'
import { ROUTES } from '../../../consts/Routes'
import CustomAutoComplete from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'

interface ManufactureTabProps {
  manufacturerList: ManufactureListType[]
}

const ManufacturerTab: React.FC<ManufactureTabProps> = ({ manufacturerList }): JSX.Element => {
  const navigate = useNavigate()
  const [selectedManufacturer, setSelectedManufacturer] = useState<ManufactureListType | null>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const handleAutocompleteChange = (event: any, newValue: ManufactureListType | null): void => {
    setSelectedManufacturer(newValue)
    setIsButtonDisabled(newValue === null)
  }

  const handleButtonClick = (): void => {
    if (selectedManufacturer !== null) {
      localStorage.setItem('platform_id', selectedManufacturer.manufacturer_platform_id)
      localStorage.setItem('manufacturer_operator_name', selectedManufacturer.manufacturer)
      navigate(ROUTES.CHAT)
    }
  }
  return (
    <>
      <Box mb={2} mt={2}>
        <CustomAutoComplete
          options={manufacturerList}
          getOptionLabel={(manufacturerList) => `${manufacturerList.manufacturer} ${manufacturerList.manufacturer_platform_id}`}
          value={selectedManufacturer}
          onChange={handleAutocompleteChange}
          placeholder="Select Manufacturer..."
        />
      </Box>
      <CustomButton
        onClick={handleButtonClick}
        disabled={isButtonDisabled}
        label="Select Manufacturer"
      />
    </>
  )
}

export default ManufacturerTab
