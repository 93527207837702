import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ChatBotLayout from '../layouts/ChatbotLayout'
import LoginAndManufactureLayout from '../layouts/LoginAndManufactureLayout/'
import LoginPage from './Login'
import AdminLoginPage from './AdminLoginPage'
import ProtectedRoutes from './ProtectedRoutes'
import { ROUTES } from '../consts/Routes'
import { getIsIframe } from '../utils/getIsIframe'

const AppRoutes: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const isIframe = getIsIframe()

  useEffect(() => {
    if (isIframe) {
      localStorage.clear()
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    const internalUser = localStorage.getItem('internal_user')
    const platform = localStorage.getItem('platform')
    if (token === null || token === undefined) {
      navigate(ROUTES.HOME)
    }
    if (token !== null && internalUser === 'true' && platform === 'standalone') {
      navigate(ROUTES.ADMIN_LOGIN)
    }
    if (token !== null && platform !== 'standalone') {
      navigate(ROUTES.CHAT)
    }
  }, [localStorage.getItem('token')])

  return (
    <Routes>
      <Route element={<ProtectedRoutes />} >
        <Route path={ROUTES.ADMIN_LOGIN} element={<LoginAndManufactureLayout />}>
          <Route index element={<AdminLoginPage />} />
        </Route>
        <Route path={ROUTES.CHAT} element={<ChatBotLayout />}>
        </Route>
      </Route>
      <Route path={ROUTES.HOME} element={<LoginAndManufactureLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path={ROUTES.HOME + '/:sessionId/:plid'} element={<LoginAndManufactureLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
