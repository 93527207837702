import React from 'react'
import { type ChatListT } from '../types'
import { StyledUserMessageWrapper, StyledUserMessage, StyledUserTime } from './styled'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import getForcedMobile from '../../../../utils/forceMobile'

interface UserMessageProps {
  message: ChatListT
  index: number
}

const UserMessage: React.FC<UserMessageProps> = ({ message, index }): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledUserMessageWrapper key={index} isMobile={isMobile || forcedMobile}>
      <StyledUserMessage>
        {message.text}
      </StyledUserMessage>
      <StyledUserTime>
        <span>{message.timeStamp}</span>
      </StyledUserTime>
    </StyledUserMessageWrapper>
  )
}

export default UserMessage
