import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { type AlertColor } from '@mui/material/Alert'

interface CustomConversationSnackBarProps {
  message: string
  duration: number
  messageType: AlertColor
  isOpen: boolean
  onClose: (arg: boolean) => void
}

const CustomConversationSnackBar: React.FC<CustomConversationSnackBarProps> = ({ message, duration, messageType, isOpen = false, onClose }): JSX.Element => {
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }
    onClose(false)
  }

  return (
    <Snackbar open={isOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={duration} onClose={handleClose} sx={{ minWidth: '60%', bottom: '95px !important' }}>
      <MuiAlert elevation={6} variant="outlined" onClose={handleClose} severity={messageType} sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default CustomConversationSnackBar
